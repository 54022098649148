import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';

import * as styleSheet from "./dentist.module.scss"

const DentistTemplate = (props) => {

	const {
		data: {
			dentist,
			dentist: {
				id: dentistId,
				title,
				ACFDentist: {
					headshot,
					introBGTablet,
					introBGDesktop,
					introBGXL,
					dentistInfo: {
						left: {
							specialitiesHeading,
							specialities,
							insurancesHeading,
							insurances
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
							contentHeading,
							dentistContent: content,
							callout: {
								calloutBlurb,
								calloutHeading,
								calloutLink ,
								displayCallout
							}
						}
					}
				},
			},
			locations: {
				nodes: dentistLocations
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						}
					}
				}

			},
			rightArrowYellow,
			teamPage,
			arrowBlue
		}
	} = props;

	const locations = dentistLocations.filter((location, index) => {

		if( location.ACFLocation && location.ACFLocation && location.ACFLocation.locationInfo.left.dentists ){
			const dentistIDs = location.ACFLocation.locationInfo.left.dentists.map(ortho =>
				`${ortho.id}`,
			);

			return dentistIDs && dentistIDs.includes(dentistId);
		} else {
			return null;
		}
	});

	return (
		<Layout>
			<Seo post={dentist} />  
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<div className={styleSheet.mobilebreadcrumbs}>{ teamPage && teamPage.nodes && teamPage.nodes.length > 0 && <Link className={styleSheet.loclink} to={teamPage.nodes[0].link}>Our Team</Link>} <span className={styleSheet.locationname} dangerouslySetInnerHTML={{__html: title}}></span></div>
						<div className={styleSheet.dentistinfo}>
							<h1 className={styleSheet.title} dangerouslySetInnerHTML={{__html: title}}></h1>
							{ locations && locations.map( (location, index) => {
								return <Link key={location.id} className={styleSheet.locationlink} to={location.link} dangerouslySetInnerHTML={{__html: location.title}} />
							})}
						</div>
						<div className={styleSheet.dentistcontainer}>
							{ headshot && <FluidImage image={headshot} passedClass={styleSheet.image} loading={"eager"} /> }
						</div>
					</div>
					<div className={styleSheet.leftbottom}></div>
				</div>
				<div className={styleSheet.right}>
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					<div className={styleSheet.desktopbreadcrumbs}>{ teamPage && teamPage.nodes && teamPage.nodes.length > 0 && <Link className={styleSheet.loclink} to={teamPage.nodes[0].link}>Our Team</Link>} <span className={styleSheet.locationname} dangerouslySetInnerHTML={{__html: title}}></span></div>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					<div className={styleSheet.specialties}>
						<span className={styleSheet.heading}>{ specialitiesHeading ? specialitiesHeading : "Specialities" }</span>
						<ul className={styleSheet.list}>
							{ specialities && specialities.map((service, index) => {
								return (
									<li key={`lstsrvc-'${service.id}`} className={styleSheet.listitem}>
										<Link to={service.link} className={styleSheet.link}>
											<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
											<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: service.title }}></span>
										</Link>	
									</li>
								)
							})}
						</ul>
					</div>
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : "We accept the following insurance" }</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.dentistcontent}>
						<div className={styleSheet.contentleft}>
							<span className={styleSheet.gettoknow}>{ contentHeading ? contentHeading : `Get to know our ${title}.` }</span>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
					{ displayCallout && (
						<div className={styleSheet.callout}>
							<div className={styleSheet.calloutleft}>
								{ calloutHeading && <span className={styleSheet.heading} dangerouslySetInnerHTML={{__html: calloutHeading}}></span> }
								{ calloutBlurb && <p dangerouslySetInnerHTML={{__html: calloutBlurb}}></p> }
							</div>
							{ calloutLink && <WPLink passedClass={styleSheet.link} link={calloutLink} /> }
						</div>
					)}
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>We accept the following insurance.</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  dentist: wpDentist(id: {eq: $id}) {
    id
    title
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    ACFDentist {
			headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(layout: FULL_WIDTH)
        	}
				}
      }
      introBGTablet: headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(width: 560, layout: CONSTRAINED)
        	}
				}
      }
      introBGDesktop: headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(width: 840, layout: CONSTRAINED)
        	}
				}
      }
      introBGXL: headshot {
				localFile {
        	childImageSharp {
          	gatsbyImageData(layout: FULL_WIDTH)
        	}
				}
      }
      dentistInfo {
        left {
          specialitiesHeading
          specialities {
            ... on WpService {
              id
              title
              link
            }
          }
          insurancesHeading
          insurances {
            ... on WpInsurance {
              id
              ACFInsurance {
                logo {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                    extension
                  }
                }
              }
            }
          }
        }
        right {
          introContent {
            blurb
            heading
            link {
              target
              title
              url
            }
          }
          contentHeading
          dentistContent
          callout {
            calloutBlurb
            calloutHeading
            calloutLink {
              target
              title
              url
            }
            displayCallout
          }
        }
      }
    }
  }
  locations: allWpLocation {
    nodes {
      title
      id
      link
      ACFLocation {
        locationInfo {
          left {
            dentists {
              ... on WpDentist {
                id
              }
            }
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  teamPage: allWpPage(filter: {template: {templateName: {eq: "Team Page"}}}) {
    nodes {
      link
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
}
`;

export default DentistTemplate;
